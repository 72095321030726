
// // import React, { useEffect, useState } from 'react';
// // import { IoLocationOutline } from 'react-icons/io5';
// // import Row from 'react-bootstrap/Row';
// // import Col from 'react-bootstrap/Col';
// // import dis7 from '../../Images/Scale.png';
// // import '../../Style/DiscoverStyle.css'; // Example style import
// // import { Link } from 'react-router-dom';
// // import Heart from 'react-heart';
// // import axios from 'axios';
// // import { API_BASE_URL } from '../../utils';

// // function Discover() {

// // const [data ,setData]=useState([])

// //    useEffect(()=>{
// //           axios.get (`${API_BASE_URL}/property/popularproperties
// // `)
// // .then((res) => {
// //   console.log(res.data,"DDDDDDDDDDDDDDDDDDDDDDDDddd");
// //   setData(res.data)
// // })
// //    },[])








// //   const toggleHeart = (_id) => {
// //     // Handle heart toggle logic here if needed
// //     console.log(`Heart toggled for property ID: ${_id}`);
// //   };

// //   return (
// //     <div className="container">
// //        <Row>
// //          <Col>
// //            <div className="text-center">
// //               <h3>Discover Popular Properties</h3>
// //             <p>The journey to finding popular properties is an adventure filled with excitement and anticipation.</p>
// //             </div>
// //         </Col>       </Row>
// //       <Row>
// //         {data.map((property) => (
// //           <Col key={property._id} xs={12} sm={6} md={6} lg={3} className="mb-4">
// //             <div className="contain card">
// //               {property.propertyImages && property.propertyImages[0]?.propertyImage && (
// //                 <img src={property.propertyImages[0]?.propertyImage} alt="Property" height={'150px'} />
// //               )}
// //                <div className="overlay-2">
// //                <Heart
// //                   isActive={true} // Replace with logic to determine if property is liked
// //                   onClick={() => toggleHeart(property._id)}
// //                   style={{ width: '1.50rem', color: '#ff5252', marginTop: '10px' }}
// //                 />
// //                </div>
// //               <div className="overlay-1">
// //                 <button className="btn btn-danger">₹ {property.price}</button>



// //               </div>
// //               <div className="card-body">
// //                 <h6 className="text-black text-start mb-0 fw-bold">{property.propertyTitle}</h6>
// //                 <div className="text-start">
// //                   <IoLocationOutline className="text-dark" />
// //                   <Link className="ms-1 text-decoration-none paragraph text-black" to={`/property/${property._id}`}>{property.location}</Link>
// //                 </div>
// //                 <p className="items mt-0 text-end text-black">
// //                   <img src={dis7} className="me-2" alt="Scale" />
// //                   {property.totalArea} sqft
// //                 </p>
// //               </div>

// //             </div>
// //           </Col>
// //         ))}
// //       </Row>
// //     </div>
// //   );
// // }

// // export default Discover;
// import React, { useEffect, useState } from 'react';
// import { IoLocationOutline } from 'react-icons/io5';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import dis7 from '../../Images/Scale.png';
// import '../../Style/DiscoverStyle.css'; // Example style import
// import { Link } from 'react-router-dom';
// import Heart from 'react-heart';
// import axios from 'axios';
// import { API_BASE_URL } from '../../utils';
// import toast from 'react-hot-toast';

// function Discover() {
//   const [data, setData] = useState([]);
//   const [likedProperties, setLikedProperties] = useState([]);
//   useEffect(() => {
//     axios.get(`${API_BASE_URL}/property/popularproperties`)
//       .then((res) => {
//         console.log(res.data, "Fetched Popular Properties");
//         if (Array.isArray(res.data.data.properties)) {
//           // Sort properties by popularity score in descending order
//           const sortedProperties = res?.data?.data?.properties?. sort((a, b) => b.popularityScore - a.popularityScore);
//           setData(sortedProperties);
//         } else {
//           console.error('Response is not an array:', res.data);
//         }
//       })
//       .catch((error) => {
//         console.error('Error fetching popular properties:', error);
//       });
//   }, []);

//   // const toggleHeart = (_id) => {
//   //   // Handle heart toggle logic here if needed
//   //   // console.log(`Heart toggled for property ID: ${_id}`);
//   // };
//   const toggleHeart = async (_id) => {
//     try {
//       const token = localStorage.getItem('accessToken');
//       const headers = {
//         Authorization: `Bearer ${token}`,
//       };

//       if (likedProperties.some(property => property._id === _id)) {
//         await axios.put(`${API_BASE_URL}/property/dislike/${_id}`, null, { headers });
//         setLikedProperties(likedProperties.filter(property => property._id !== _id));
//         toast.success('Removed from Wishlist.');
//       } else {
//         await axios.post(`${API_BASE_URL}/property/like/${_id}`, null, { headers });

//         setLikedProperties([...likedProperties, likedProperty]);
//         toast.success('Added to Wishlist.');
//       }
//     } catch (error) {
//       console.error('Error toggling heart:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Oops...',
//         text: error.response?.data.message || 'Something went wrong!',
//       });
//     }
//   };
//   return (
//     <div className="container">
//       <Row>
//         <Col>
//           <div className="text-center">
//             <h3>Discover Popular Properties</h3>
//             <p>The journey to finding popular properties is an adventure filled with excitement and anticipation.</p>
//           </div>
//         </Col>
//       </Row>
//       <Row>
//         {Array.isArray(data) && data.length > 0 ? (
//           data.map((property) => (
//             <Col key={property._id} xs={12} sm={6} md={6} lg={3} className="mb-4">
//               <div className="contain card">
//                 {property.propertyImages && property.propertyImages[0]?.propertyImage && (
//                   <img src={property.propertyImages[0]?.propertyImage} alt="Property" height="150px" />
//                 )}
//                 <div className="overlay-2">
//                   <Heart
//                     isActive={true} // Replace with logic to determine if property is liked
//                     onClick={() => toggleHeart(property._id)}
//                     style={{ width: '1.50rem', color: '#ff5252', marginTop: '10px' }}
//                   />
//                 </div>
//                 <div className="overlay-1">
//                   <button className="btn btn-danger">₹ {property.price}</button>
//                 </div>
//                 <div className="card-body">
//                   <h6 className="text-black text-start mb-0 fw-bold">{property.propertyTitle}</h6>
//                   <div className="text-start">
//                     <IoLocationOutline className="text-dark" />
//                     <Link className="ms-1 text-decoration-none paragraph text-black" to={`/property/${property._id}`}>{property.location}</Link>
//                   </div>
//                   <p className="items mt-0 text-end text-black">
//                     <img src={dis7} className="me-2" alt="Scale" />
//                     {property.totalArea} sqft
//                   </p>
//                 </div>
//               </div>
//             </Col>
//           ))
//         ) : (
//           <Col>
//             <div className="text-center">
//               <p>No popular properties found.</p>
//             </div>
//           </Col>
//         )}
//       </Row>
//     </div>
//   );
// }

// export default Discover;
// import React, { useEffect, useState } from 'react';
// import { IoLocationOutline } from 'react-icons/io5';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import dis7 from '../../Images/Scale.png';
// import '../../Style/DiscoverStyle.css'; // Example style import
// import { Link } from 'react-router-dom';
// import Heart from 'react-heart';
// import axios from 'axios';
// import { API_BASE_URL } from '../../utils';
// import toast from 'react-hot-toast';
// import Swal from 'sweetalert2';

// function Discover() {
//   const [data, setData] = useState([]);
//   const [likedProperties, setLikedProperties] = useState([]);

//   useEffect(() => {
//     axios.get(`${API_BASE_URL}/property/popularproperties`)
//       .then((res) => {
//         console.log(res.data, "Fetched Popular Properties");
//         if (Array.isArray(res.data.data.properties)) {
//           // Sort properties by popularity score in descending order
//           const sortedProperties = res.data.data.properties.sort((a, b) => b.popularityScore - a.popularityScore);
//           setData(sortedProperties);
//         } else {
//           console.error('Response is not an array:', res.data);
//         }
//       })
//       .catch((error) => {
//         console.error('Error fetching popular properties:', error);
//       });
//   }, []);

//   const toggleHeart = async (_id) => {
//     try {
//       const token = localStorage.getItem('accessToken');
//       const headers = {
//         Authorization: `Bearer ${token}`,
//       };

//       if (likedProperties.some(property => property._id === _id)) {
//         await axios.put(`${API_BASE_URL}/property/dislike/${_id}`, null, { headers });
//         setLikedProperties(likedProperties.filter(property => property._id !== _id));
//         toast.success('Removed from Wishlist.');
//       } else {
//         await axios.post(`${API_BASE_URL}/property/like/${_id}`, null, { headers });
//         const likedProperty = data.find(property => property._id === _id);
//         setLikedProperties([...likedProperties, likedProperty]);
//         toast.success('Added to Wishlist.');
//       }
//     } catch (error) {
//       console.error('Error toggling heart:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Oops...',
//         text: error.response?.data.message || 'Something went wrong!',
//       });
//     }
//   };
//   const formatPrice = (price) => {
//     if (price >= 10000000) {
//       return (price / 10000000).toFixed(2) + ' Cr';
//     } else if (price >= 100000) {
//       return (price / 100000).toFixed(2) + ' L';
//     } else {
//       return price.toString();
//     }
//   };

//   return (
//     <div className="container">
//       <Row>
//         <Col>
//           <div className="text-start">
//             <h3>Discover Popular Properties</h3>
//             <p>The journey to finding popular properties is an adventure filled with excitement and anticipation.</p>
//           </div>
//         </Col>
//       </Row>
//       <Row>
//         {Array.isArray(data) && data.length > 0 ? (
//           data.map((property) => (
//             <Col key={property._id} xs={12} sm={6} md={6} lg={3} className="mb-4">
//               <div className="contain card sha">
//                 {property.propertyImages && property.propertyImages[0]?.propertyImage && (
//                   <img src={property.propertyImages[0]?.propertyImage} alt="Property" height="150px" className='rounded' />
//                 )}
//                 <div className="overlay-2">
//                   <Heart
//                     isActive={likedProperties.some(likedProperty => likedProperty._id === property._id)}
//                     onClick={() => toggleHeart(property._id)}
//                     style={{ width: '1.50rem', color: '#ff5252', marginTop: '10px' }}
//                   />
//                 </div>
//                 <div className="overlay-1">
//                   <button className="btn btn-danger">₹ {formatPrice(property.price)}</button>
//                 </div>
//                 <div className="card-body">
//                   <h5 className="text-black text-start mb-0 card-title">{property.propertyTitle}</h5>
//                   <div className="text-start">
//                     <IoLocationOutline className="text-dark" />
//                     <Link className="ms-1 text-decoration-none paragraph text-black" to={`/property/${property._id}`}>{property.location}</Link>
//                   </div>
//                   <p className="items mt-0 text-end text-black">
//                     <img src={dis7} className="me-2" alt="Scale" />
//                     {property.totalArea} sqft
//                   </p>
//                 </div>
//               </div>
//             </Col>
//           ))
//         ) : (
//           <Col>
//             <div className="text-center">
//               <p>No popular properties found.</p>
//             </div>
//           </Col>
//         )}

//       </Row>
//       <div className="mt-3 mb-5 justify-content-center text-center">
//         <Link to="/viewsinglepage">
//           <button className="btn btn-danger rounded-pill fw-light">See All Listing</button>
//         </Link>
//       </div>
//     </div>
//   );
// }

// export default Discover;
import React, { useEffect, useState } from 'react';
import { IoLocationOutline } from 'react-icons/io5';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import dis7 from '../../Images/Scale.png';
import '../../Style/DiscoverStyle.css'; // Example style import
import { Link } from 'react-router-dom';
import Heart from 'react-heart';
import axios from 'axios';
import { API_BASE_URL } from '../../utils';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
// import axiosInstance from '../../utils/429Error';

function Discover() {
  const [data, setData] = useState([]);
  const [likedProperties, setLikedProperties] = useState([]);

  useEffect(() => {
    axios.get(`${API_BASE_URL}/property/popularproperties`)
      .then((res) => {
        console.log(res.data, "Fetched Popular Properties");
        if (Array.isArray(res.data.data.properties)) {
          // Sort properties by popularity score in descending order
          const sortedProperties = res.data.data.properties.sort((a, b) => b.popularityScore - a.popularityScore);
          setData(sortedProperties);
        } else {
          console.error('Response is not an array:', res.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching popular properties:', error);
      });
  }, []);

  const toggleHeart = async (_id) => {
    console.log(_id)
    try {
      const token = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      if (likedProperties.some(property => property._id === _id)) {
        await axios.put(`${API_BASE_URL}/property/dislike/${_id}`, null, { headers });
        setLikedProperties(likedProperties.filter(property => property._id !== _id));
        toast.success('Removed from Wishlist.');
      } else {
        await axios.post(`${API_BASE_URL}/property/like/${_id}`, null, { headers });
        const likedProperty = data.find(property => property._id === _id);
        setLikedProperties([...likedProperties, likedProperty]);
        toast.success('Added to Wishlist.');
      }
    } catch (error) {
      console.error('Error toggling heart:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response?.data.message || 'Something went wrong!',
      });
    }
  };

  const formatPrice = (price) => {
    if (price >= 10000000) {
      return (price / 10000000).toFixed(2) + ' Cr';
    } else if (price >= 100000) {
      return (price / 100000).toFixed(2) + ' L';
    } else {
      return price.toString();
    }
  };

  // const  = (title, minLength) => {
  //   if (title.length > minLength) {
  //     return title.substring(0, minLength) + '...';
  //   }
  //   return title;
  // };
  // const truncateTitle = (text, maxLength) => {
  //   if (!text) {
  //     return '';
  //   }
  //   if (text.length > maxLength) {
  //     return text.substring(0, maxLength) + '...';
  //   }
  //   return text;
  // };
  const truncateTitle = (text, maxLength) => text ? (text.length > maxLength ? text.substring(0, maxLength) + '...' : text) : '';

  return (
    <div className="container">
      <Row>
        <Col>
          <div className="text-start">
            <h3>Discover Popular Properties</h3>
            <p>The journey to finding popular properties is an adventure filled with excitement and anticipation.</p>
          </div>
        </Col>
      </Row>
      <Row>
        {Array.isArray(data) && data.length > 0 ? (
          data.map((property) => (
            <Col key={property._id} xs={12} sm={6} md={6} lg={3} className="mb-4">
              <Link className="ms-1 text-decoration-none paragraph text-black" to={`/viewalldetails/${property._id}`}>
              <div className="contain card  sha">
                {property.propertyImages && property.propertyImages[0]?.propertyImage && (
                  <img src={property.propertyImages[0]?.propertyImage} alt="Property" height="150px" className='rounded' />
                )}
                <div className="overlay-2">
                  <Heart
                    isActive={likedProperties.some(likedProperty => likedProperty._id === property._id)}
                    onClick={() => toggleHeart(property._id)}
                    style={{ width: '1.50rem', color: '#ff5252', marginTop: '10px' }}
                  />
                </div>
                <div className="overlay-1">
                  <button className="btn btn-danger">₹ {formatPrice(property.price)}</button>
                </div>
                <div className="card-body">
                  <h6 className="text-black text-start mb-0 card-title">{truncateTitle(property.propertyTitle, 30)}</h6>
                  <div className="text-start">
                    <IoLocationOutline className="text-dark" />
                       <span> {property.location}  </span> 
                  </div>
                  <p className="items mt-0 text-end text-black">
                    <img src={dis7} className="me-2" alt="Scale" />
                    <span className='fw-light'>
                      {property.totalArea} sqft
                    </span>
                  </p>
                </div>
              </div>
              </Link>
            </Col>
          ))
        ) : (
          <Col>
            <div className="text-center">
              <p>No popular properties found.</p>
            </div>
          </Col>
        )}
      </Row>
      <div className="mt-3 mb-5 justify-content-center text-center">
        <Link to="/viewsinglepage">
          <button className="btn btn-danger rounded-pill fw-light">See All Listing</button>
        </Link>
      </div>
    </div>
  );
}

export default Discover;
